<div class="container-nav-sample">
  <div class="wrapper-nav-sample">
    <h1>Explore the universe of <br /><span>STAR WARS JOKES</span></h1>
    <div class="wrapper-link-sample">
      <div class="sample-list">
        <h2 (click)="goToAllJokes()" >All Star Wars Jokes</h2>
        <img (click)="goToAllJokes()"  src="https://i.imgur.com/zMHA8TU.gif" alt="STWJOKES" />
      </div>

      <div class="sample-list">
        <h2 (click)="goToRandomJokes()" >Random Joke</h2>
        <img (click)="goToRandomJokes()"  src="https://imagesvc.meredithcorp.io/v3/mm/gif?url=https%3A%2F%2Fimages.hellogiggles.com%2Fuploads%2F2017%2F08%2F15060231%2Faryalightsaber.gif" alt="STWJOKES" />
      </div>

      <div class="sample-list">
        <h2 (click)="goToLSJokes()" >Light Side Jokes</h2>
        <img (click)="goToLSJokes()"  src="https://media.giphy.com/media/KGjrGOx9oAntiXjvrn/giphy.gif" alt="STWJOKES" />
      </div>

      <div class="sample-list">
        <h2 (click)="goToDSJokes()" >Dark Side Jokes</h2>
        <img (click)="goToDSJokes()" src="https://66.media.tumblr.com/239fc168c179eb12020d9e03e9c8bddb/tumblr_oyz5toXgIg1rnikqmo5_500.gifv" alt="STWJOKES" />
      </div>

      <div class="sample-list">
        <h2 (click)="goToNSJokes()" >Neutral Creatures Jokes</h2>
        <img (click)="goToNSJokes()" src="https://media.giphy.com/media/3oeSAChz3fpCVUypSU/giphy.gif" alt="STWJOKES" />
      </div>
    </div>
  </div>
</div>
