import { Component, OnInit } from "@angular/core";
import { JokesService } from "../../services/jokes.service";
import { Router } from "@angular/router";

interface HtmlInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Component({
  selector: "app-new-joke",
  templateUrl: "./new-joke.component.html",
  styleUrls: ["./new-joke.component.scss"]
})
export class NewJokeComponent implements OnInit {
  file: File;
  imgSelected: string | ArrayBuffer;

  constructor(private jokesService: JokesService, private router: Router) {}

  ngOnInit(): void {}

  onImgSelected(event: HtmlInputEvent): void {
    if (event.target.files && event.target.files[0]) {
      this.file = <File>event.target.files[0];
      // image preview
      const reader = new FileReader();
      reader.onload = e => (this.imgSelected = reader.result);
      reader.readAsDataURL(this.file);
    }
  }

  uploadImg(
    name: HTMLInputElement,
    side: HTMLInputElement,
    value: HTMLInputElement,
    token: HTMLInputElement
  ): boolean {
    this.jokesService
      .createJoke(name.value, side.value, value.value, token.value, this.file)
      .subscribe(
        res => {
          this.router.navigate(['/joke'])
        },
        err => console.log(err)
      );
    return false;
  }
}
