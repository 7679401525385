<div class="load-preview" *ngIf="!joke; else main_preview">
  <h1>Loading...</h1>
</div>

<ng-template #main_preview>
  <div class="container-joke-by-id">
    <div class="wrapper-joke-by-id">
      <div
        class="img-character"
        [ngClass]="{
          light: joke.side == 'Light Side',
          dark: joke.side == 'Dark Side',
          neutral: joke.side == 'Neutral'
        }"
      >
        <img [src]="'https://starwarsjokes.aemabit.com/' + joke.img_url" alt="STW" />
      </div>
      <h1>{{ joke.name }}</h1>
      <h3>{{ joke.value }}</h3>
      <p
        [ngClass]="{
          lightSide: joke.side == 'Light Side',
          darkSide: joke.side == 'Dark Side',
          neutralSide: joke.side == 'Neutral'
        }"
      >
        {{ joke.side }}
      </p>
      <button
        [ngClass]="{
          buttonLight: joke.side == 'Light Side',
          buttonDark: joke.side == 'Dark Side',
          buttonNeutral: joke.side == 'Neutral'
        }"
        (click)="refresh()"
      >
        I'm Feeling Lucky
      </button>
    </div>
  </div>
</ng-template>
