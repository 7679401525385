import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Joke } from "../interfaces/Joke";

@Injectable({
  providedIn: "root"
})
export class JokesService {
  URI = "https://starwarsjokes.aemabit.com/api/jokes";
  RAN = "https://starwarsjokes.aemabit.com/api/randomjoke";
  STWLS = "https://starwarsjokes.aemabit.com/api/lightside";
  STWDS = "https://starwarsjokes.aemabit.com/api/darkside";
  STWNC = "https://starwarsjokes.aemabit.com/api/neutralcreatures";

  constructor(private http: HttpClient) {}

  createJoke(
    name: string,
    side: string,
    value: string,
    token: string,
    img_url: File
  ) {
    const fd = new FormData();
    fd.append("name", name);
    fd.append("side", side);
    fd.append("value", value);
    fd.append("token", token);
    fd.append("image", img_url);
    return this.http.post(this.URI, fd);
  }

  getJokes() {
    return this.http.get<Joke[]>(this.URI);
  }

  getJokesById(id: string) {
    return this.http.get<Joke>(`${this.URI}/${id}`);
  }

  deleteJoke(id: string) {
    return this.http.delete<Joke>(`${this.URI}/${id}`);
  }

  updateJoke(id: string, name: string, side: string, value: string) {
    return this.http.put<Joke>(`${this.URI}/${id}`, { name, side, value });
  }

  getRandomJoke() {
    return this.http.get<Joke>(`${this.RAN}`);
  }

  getLightSideJoke() {
    return this.http.get<Joke[]>(`${this.STWLS}`);
  }

  getDarkSideJoke() {
    return this.http.get<Joke[]>(`${this.STWDS}`);
  }

  getNeutralCreatureJokes() {
    return this.http.get<Joke[]>(`${this.STWNC}`);
  }
}
