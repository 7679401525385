<div class="container-usage">
  <div class="wrapper-usage">
    <img
      src="https://vignette.wikia.nocookie.net/starwars/images/c/cc/Star-wars-logo-new-tall.jpg/revision/latest?cb=20190313021755"
      alt="STWJokeLogo"
    />
    <p>
      <span>starwarsjokes.aemabit.com</span>
      is a free JSON API created to test && practice fetching data from an API.
    </p>
    <div class="ctx-intro-usage">
      <h2>Usage</h2>
      <div class="ctx-usage">
        <h3>Retrieve a random star wars joke in JSON format.</h3>
        <p>
          GET
          <a href="https://starwarsjokes.aemabit.com/api/randomjoke"
            >"https://starwarsjokes.aemabit.com/api/randomjoke"</a
          >
        </p>
        <h3>Example response:</h3>
        <p>
          "_id": "5e7ceaac102721313e7d8325", <br />
          "name": "Mace Window", <br />
          "side": "Light Side", <br />
          "value": "I dont know why you are here, you are the only one who
          breaks the rules.", <br />
          "img_url": "uploads/f35e1714-aba5-4443-947c-df09b0bc40b2.jpg",<br />
          "__v": 0 <br />
        </p>
      </div>
      <div class="ctx-usage-stw">
        <h3>Retrieve all star wars joke in JSON format.</h3>
        <p>
          GET
          <a href="https://starwarsjokes.aemabit.com/api/jokes"
            >"https://starwarsjokes.aemabit.com/api/jokes"</a
          >
        </p>
      </div>
      <div class="ctx-usage-stw">
        <h3>Retrieve (Light Side) star wars joke in JSON format.</h3>
        <p>
          GET
          <a href="https://starwarsjokes.aemabit.com/api/lightside"
            >"https://starwarsjokes.aemabit.com/api/lightside"</a
          >
        </p>
      </div>
      <div class="ctx-usage-stw">
        <h3>Retrieve (Dark Side) star wars joke in JSON format.</h3>
        <p>
          GET
          <a href="https://starwarsjokes.aemabit.com/api/darkside"
            >"https://starwarsjokes.aemabit.com/api/darkside"</a
          >
        </p>
      </div>
      <div class="ctx-usage-stw">
        <h3>Retrieve (Neutral Creatures) star wars joke in JSON format.</h3>
        <p>
          GET
          <a href="https://starwarsjokes.aemabit.com/api/neutralcreatures">
            "https://starwarsjokes.aemabit.com/api/neutralcreatures"</a
          >
        </p>
      </div>
      <div class="end-page">
        <h3>Thank you for watching. AEMABIT</h3>
        <p>
          "May the Force be with you"
        </p>
        <div class="end-links">
          <p>
            <fa-icon [icon]="faCodeBranch"></fa-icon
            ><a href="https://github.com/aemabit" target="_blank">GitHub</a>
          </p>
          <p>
            <fa-icon [icon]="faInfo"></fa-icon>
            <a href="https://www.linkedin.com/in/andres-mejias/" target="_blank"
              >Linkedin</a
            >
          </p>
          <p>
            <fa-icon [icon]="faEnvelope"></fa-icon>
            <a href="mailito:aemabit@gmail.com" target="_blank">Send Email</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
