<div class="container-jokes">
  <div class="wrapper-jokes">
    <h1>All Jokes in the universe of <br /><span>STAR WARS JOKES</span></h1>
    <div class="wrapper-ctx-jokes">
      <div class="ctx-joke" *ngFor="let elements of starWarsJokes">
        <div
          [ngClass]="{
            light: elements.side == 'Light Side',
            dark: elements.side == 'Dark Side',
            neutral: elements.side == 'Neutral'
          }"
          (click)="selectedCharacter(elements._id)"
        >
          <img
            [src]="'https://starwarsjokes.aemabit.com/' + elements.img_url"
            alt="character"
          />
        </div>
        <h3>{{ elements.name }}</h3>
      </div>
    </div>
  </div>
</div>
