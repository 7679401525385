<div class="container-side-joke">
  <div class="wrapper-side-joke">
    <h1>Neutral Creatures Jokes</h1>
    <div class="list-side-joke" *ngFor="let elements of stwSideJoke">
      <img [src]="'https://starwarsjokes.aemabit.com/' + elements.img_url" alt="avatar" />
      <h4>{{ elements.name }}</h4>
      <span>{{ elements.value }}</span>
    </div>
  </div>
</div>
