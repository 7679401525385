import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-list-joke",
  templateUrl: "./list-joke.component.html",
  styleUrls: ["./list-joke.component.scss"]
})
export class ListJokeComponent implements OnInit {
  starWarsJokes = [];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  goToAllJokes() {
    this.router.navigate(["/jokes/all"]);
  }

  goToRandomJokes() {
    this.router.navigate(["/jokes/random"]);
  }

  goToLSJokes() {
    this.router.navigate(["jokes/only-light-side-jokes"]);
  }

  goToDSJokes() {
    this.router.navigate(["jokes/only-dark-side-jokes"]);
  }

  goToNSJokes() {
    this.router.navigate(["jokes/only-neutral-side-jokes"]);
  }
}
