import { Component, OnInit } from "@angular/core";
import {
  faCodeBranch,
  faInfo,
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-usage",
  templateUrl: "./usage.component.html",
  styleUrls: ["./usage.component.scss"]
})
export class UsageComponent implements OnInit {
  faCodeBranch = faCodeBranch;
  faInfo = faInfo;
  faEnvelope = faEnvelope;
  constructor() {}

  ngOnInit(): void {}
}
