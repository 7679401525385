<div class="container-form">
  <div class="wrapper-form">
    <h1>Create a new Joke</h1>
    <div class="ctx-form">
      <form 
      <!-- (submit)="uploadImg(name, side, value, token)" -->
      >
        <div class="form-group">
          <input
            type="text"
            name="name"
            placeholder="Name of Character"
            required
            #name
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            name="side"
            placeholder="Side of the force"
            required
            #side
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            name="value"
            placeholder="Description Joke"
            required
            #value
          />
        </div>
        <div class="form-group">
          <input type="text" name="token" placeholder="Token" required #token />
        </div>
        <div class="form-group">
          <input
            type="file"
            name="img_url"
            placeholder="Choose a file"
            (change)="onImgSelected($event)"
            required
            #imgInput
          />
          <img
            [src]="
              imgSelected ||
              'https://thefittingsource.com/wp-content/uploads/2017/12/temp-inventory-landing.jpg'
            "
            alt="img-avaliable"
            (click)="imgInput.click()"
          />
        </div>
        <button type="submit" disabled class="btn-primary">Submit</button>
      </form>
    </div>
  </div>
</div>
