import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ListJokeComponent } from "./components/list-joke/list-joke.component";
import { NewJokeComponent } from "./components/new-joke/new-joke.component";
import { PreviewJokeComponent } from "./components/preview-joke/preview-joke.component";
import { UsageComponent } from './components/usage/usage.component';
import { RandomJokeComponent } from './components/random-joke/random-joke.component';
import { AllJokeComponent } from './components/all-joke/all-joke.component';
import { LightSideJokeComponent } from './components/light-side-joke/light-side-joke.component';
import { DarkSideJokeComponent } from './components/dark-side-joke/dark-side-joke.component';
import { NeutralSideJokeComponent } from './components/neutral-side-joke/neutral-side-joke.component';

const routes: Routes = [
  {
    path: "joke",
    component: ListJokeComponent
  },
  {
    path: "joke/new",
    component: NewJokeComponent
  },
  {
    path: "joke/:id",
    component: PreviewJokeComponent
  },
  {
    path: "",
    component: UsageComponent
  },
  {
    path: "jokes/random",
    component: RandomJokeComponent
  },
  {
    path: "jokes/all",
    component: AllJokeComponent
  },
  {
    path: "jokes/only-light-side-jokes",
    component: LightSideJokeComponent
  },
  {
    path: "jokes/only-dark-side-jokes",
    component: DarkSideJokeComponent
  },
  {
    path: "jokes/only-neutral-side-jokes",
    component: NeutralSideJokeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
