import { Component, OnInit } from "@angular/core";
import { JokesService } from "../../services/jokes.service";

@Component({
  selector: "app-neutral-side-joke",
  templateUrl: "./neutral-side-joke.component.html",
  styleUrls: ["./neutral-side-joke.component.scss"]
})
export class NeutralSideJokeComponent implements OnInit {
  stwSideJoke = [];

  constructor(private jokeService: JokesService) {}

  ngOnInit() {
    this.jokeService.getNeutralCreatureJokes().subscribe(
      res => {
        this.stwSideJoke = res;
      },
      err => console.log(err)
    );
  }
}
