import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { JokesService } from "../../services/jokes.service";

import { Joke } from "../../interfaces/Joke";

@Component({
  selector: "app-preview-joke",
  templateUrl: "./preview-joke.component.html",
  styleUrls: ["./preview-joke.component.scss"]
})
export class PreviewJokeComponent implements OnInit {
  id: string;
  joke: Joke;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private jokesService: JokesService
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe(params => {
      this.id = params["id"];
      this.jokesService.getJokesById(this.id).subscribe(
        res => {
          this.joke = res;
        },
        err => console.log(err)
      );
    });
  }

  deleteJokeById(id: string) {
    this.jokesService.deleteJoke(id).subscribe(
      res => {
        this.router.navigate(["/joke"]);
      },
      err => console.log(err)
    );
  }

  updateJokeById(
    name: HTMLInputElement,
    side: HTMLInputElement,
    value: HTMLInputElement
  ): boolean {
    this.jokesService
      .updateJoke(this.id, name.value, side.value, value.value)
      .subscribe(
        res => console.log(res),
        err => console.log(err)
      );
    return false;
  }
}
