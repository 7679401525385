<div class="load-preview" *ngIf="!joke; else main_preview">
  <h1>Loading...</h1>
</div>

<ng-template #main_preview>
  <div class="container-joke-by-id">
    <div class="wrapper-joke-by-id">
      <div
        class="img-character"
        [ngClass]="{
          light: joke.side == 'Light Side',
          dark: joke.side == 'Dark Side',
          neutral: joke.side == 'Neutral'
        }"
      >
        <img [src]="'https://starwarsjokes.aemabit.com/' + joke.img_url" alt="STW" />
      </div>
      <h1>{{ joke.name }}</h1>
      <h3>{{ joke.value }}</h3>
      <p
        [ngClass]="{
          lightSide: joke.side == 'Light Side',
          darkSide: joke.side == 'Dark Side',
          neutralSide: joke.side == 'Neutral'
        }"
      >
        {{ joke.side }}
      </p>
    </div>
    <div class="wrapper-form-update">
      <h2>Update Character || Joke</h2>
      <form (submit)="updateJokeById(name, side, value)">
        <div class="form-group">
          <input
            type="text"
            #name
            placeholder="Name Character"
            [value]="joke.name"
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            #side
            placeholder="Choose a Side of the force"
            [value]="joke.side"
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            #value
            placeholder="Write the joke"
            [value]="joke.value"
          />
        </div>
        <button type="submit">
          Update
        </button>
      </form>
      <button (click)="deleteJokeById(joke._id)">
        Delete
      </button>
    </div>
  </div>
</ng-template>
