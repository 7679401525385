import { Component, OnInit } from "@angular/core";
import { JokesService } from "../../services/jokes.service";

@Component({
  selector: "app-dark-side-joke",
  templateUrl: "./dark-side-joke.component.html",
  styleUrls: ["./dark-side-joke.component.scss"]
})
export class DarkSideJokeComponent implements OnInit {
  stwSideJoke = [];

  constructor(private jokesService: JokesService) {}

  ngOnInit() {
    this.jokesService.getDarkSideJoke().subscribe(
      res => {
        this.stwSideJoke = res;
      },
      err => console.log(err)
    );
  }
}
