import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NavigationComponent } from "./components/navigation/navigation.component";
import { NewJokeComponent } from "./components/new-joke/new-joke.component";
import { PreviewJokeComponent } from "./components/preview-joke/preview-joke.component";
import { ListJokeComponent } from "./components/list-joke/list-joke.component";
import { UsageComponent } from "./components/usage/usage.component";
import { RandomJokeComponent } from './components/random-joke/random-joke.component';
import { LightSideJokeComponent } from './components/light-side-joke/light-side-joke.component';
import { DarkSideJokeComponent } from './components/dark-side-joke/dark-side-joke.component';
import { NeutralSideJokeComponent } from './components/neutral-side-joke/neutral-side-joke.component';
import { AllJokeComponent } from './components/all-joke/all-joke.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    NewJokeComponent,
    PreviewJokeComponent,
    ListJokeComponent,
    UsageComponent,
    RandomJokeComponent,
    LightSideJokeComponent,
    DarkSideJokeComponent,
    NeutralSideJokeComponent,
    AllJokeComponent
  ],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, FontAwesomeModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
