import { Component, OnInit } from "@angular/core";
import { JokesService } from "../../services/jokes.service";

import { Joke } from "../../interfaces/Joke";

@Component({
  selector: "app-random-joke",
  templateUrl: "./random-joke.component.html",
  styleUrls: ["./random-joke.component.scss"]
})
export class RandomJokeComponent implements OnInit {
  joke: Joke;

  constructor(private jokesService: JokesService) {}

  ngOnInit() {
    this.jokesService.getRandomJoke().subscribe(
      res => {
        this.joke = res;
      },
      err => console.log(err)
    );
  }

  refresh(): void {
    window.location.reload();
  }
}
