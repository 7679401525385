import { Component, OnInit } from '@angular/core';
import { JokesService } from "../../services/jokes.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-all-joke',
  templateUrl: './all-joke.component.html',
  styleUrls: ['./all-joke.component.scss']
})
export class AllJokeComponent implements OnInit {
  starWarsJokes = [];

  constructor(private jokeService: JokesService, private router: Router) {}

  ngOnInit() {
    this.jokeService.getJokes().subscribe(
      res => {
        this.starWarsJokes = res;
      },
      err => console.log(err)
    );
  }

  selectedCharacter(id: string) {
    this.router.navigate(['/joke', id])
  }
}
